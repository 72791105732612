<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="project">
            <project-select v-model="form.project" :label-in-value="true"></project-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="sourceType">
            <a-select v-model="form.sourceType" :options="sourceTypeData" placeholder="请选择资源类型"
              @change="sourceTypeChange"></a-select>
          </a-form-model-item>
        </a-col>

        <a-col v-if="sourceTypeSelect === 'host'" :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="osType">
            <os-type-select v-model="form.osType"></os-type-select>
          </a-form-model-item>
        </a-col>
        <a-col v-else-if="sourceTypeSelect === 'middleware'" :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="middlewareType">
            <middleware-type-select v-model="form.middlewareType"></middleware-type-select>
          </a-form-model-item>
        </a-col>
        <a-col v-else :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="databaseType">
            <database-type-select v-model="form.databaseType"></database-type-select>
          </a-form-model-item>
        </a-col>

        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="key">
            <key-select v-model="form.key" :sourceType="sourceTypeSelect" :osType="form.osType"
              :middlewareType="form.middlewareType" :databaseType="form.databaseType"></key-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <a-table :columns="columns" :data-source="dataSource" :loading="loading" :pagination="false" row-key="id"
        style="margin-bottom: 16px;">
        <template slot="name" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.name">{{ record.name }}</a-tooltip>
        </template>
        <template slot="value" slot-scope="record">
          {{ record | getValue(record) }}
        </template>
        <template slot="operation" slot-scope="record">
          <a @click="showPreferenceChart(record)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="eye"></a-icon>
              <span>查看</span>
            </a-space>
          </a>
            <performance-modal  ref="historyTabModal" :datetime-range="datetimeRange" :preferenceInfo="preferenceInfo"
            ></performance-modal>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { getHostMonitorPerformance } from '@/api/host'
import { getDatabaseMonitorPerformance } from '@/api/database'
import { getMiddlewareMonitorPerformance } from '@/api/middleware'
import ProjectSelect from '@/components/select/ProjectSelect'
import DatabaseTypeSelect from '@/components/select/DatabaseTypeSelect'
import MiddlewareTypeSelect from '@/components/select/MiddlewareTypeSelect'
import OsTypeSelect from '@/components/select/OsTypeSelect'
import KeySelect from './modules/KeySelect.vue'
import PerformanceModal from '@/components/chart/MonitorChartCard/PerformanceModal'
import moment from 'moment'

export default {
  name: 'PerformanceManagement',
  components: {
    ProjectSelect,
    DatabaseTypeSelect,
    MiddlewareTypeSelect,
    OsTypeSelect,
    KeySelect,
    PerformanceModal
  },
  data () {
    return {
      dataSource: [],
      form: {
        project: undefined,
        key: { label: 'CPU 使用率', key: 'system.cpu.util' },
        osType: 'linux',
        databaseType: 'mysql',
        middlewareType: 'nginx',
        sourceType: 'host'
      },
      sourceTypeSelect: 'host',
      sourceTypeData: [
        { title: '主机', value: 'host' },
        { title: '中间件', value: 'middleware' },
        { title: '数据库', value: 'database' }
      ],
      columns: [
        { title: '资源名称', width: 200, ellipsis: true, scopedSlots: { customRender: 'name' } },
        { title: '值', width: 200, scopedSlots: { customRender: 'value' }, sorter: (a, b) => a.value - b.value },
        { title: '操作', width: 160, fixed: 'right', scopedSlots: { customRender: 'operation' } }
      ],
      loading: false,
      datetimeRange: [moment().subtract(1, 'hour'), moment()],
      preferenceInfo: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      const params = {}
      if (this.form.project) params.project_id = this.form.project.key
      if (this.form.sourceType === 'host' && this.form.osType) params.os_type = this.form.osType
      if (this.form.sourceType === 'middleware' && this.form.middlewareType) params.middleware_type = this.form.middlewareType
      if (this.form.sourceType === 'database' && this.form.databaseType) params.database_type = this.form.databaseType
      if (this.form.key) params.key = this.form.key.key
      switch (this.sourceTypeSelect) {
        case 'host':
          getHostMonitorPerformance(params).then(res => {
            const data = res.data
            this.dataSource = data.data
            if (this.dataSource.length) {
              this.preferenceInfo.chartKey = this.form.key
              this.preferenceInfo.sourceId = this.dataSource[0].id
              this.preferenceInfo.sourceType = 'host'
            }
          }).finally(() => {
            this.loading = false
          })
          break
        case 'middleware':
          getMiddlewareMonitorPerformance(params).then(res => {
            const data = res.data
            this.dataSource = data.data
            if (this.dataSource.length) {
              this.preferenceInfo.chartKey = this.form.key
              this.preferenceInfo.sourceId = this.dataSource[0].id
              this.preferenceInfo.sourceType = 'middleware'
            }
          }).finally(() => {
            this.loading = false
          })
          break
        case 'database':
          getDatabaseMonitorPerformance(params).then(res => {
            const data = res.data
            this.dataSource = data.data
            if (this.dataSource.length) {
              this.preferenceInfo.chartKey = this.form.key
              this.preferenceInfo.sourceId = this.dataSource[0].id
              this.preferenceInfo.sourceType = 'database'
            }
          }).finally(() => {
            this.loading = false
          })
          break
      }
    },
    search () {
      this.fetch()
    },
    showPreferenceChart (data) {
      this.preferenceInfo.sourceId = data.id
      this.$refs.historyTabModal.show(this.preferenceInfo)
    },
    sourceTypeChange (value) {
      this.sourceTypeSelect = value
    }
  },
  filters: {
    getValue: function (v) {
      return v.value + v.unit
    }
  }
}
</script>

<style>
</style>

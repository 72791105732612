<template>
  <a-modal v-model="visible" :destroy-on-close="true" :footer="null" :title="preferenceInfo.chartKey.label" :width="1080">
    <a-form-model layout="inline" style="margin-bottom: 16px;">
      <a-form-model-item>
        <a-range-picker :allow-clear="false" :default-value="dates" :disabled-date="disabledDate" :ranges="ranges"
          :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" @ok="v => { this.dates = v }"></a-range-picker>
      </a-form-model-item>
    </a-form-model>

     <performance-chart :datetime-range="dates" :preferenceInfo="preferenceInfo" ></performance-chart>
  </a-modal>
</template>
<script>
import PerformanceChart from './modules/PerformanceChart'
import { getDatetimeRanges } from '@/utils'
import moment from 'moment'

export default {
  name: 'PerformanceModal',
  components: { PerformanceChart },
  props: {
    datetimeRange: { type: Array, required: true }
  },
  data () {
    return {
      visible: false,
      dates: this.datetimeRange || [],
      ranges: getDatetimeRanges(),
      preferenceInfo: {
        chartKey: ''
      }
    }
  },
  watch: {
    datetimeRange () {
      this.dates = this.datetimeRange
    }
  },
  methods: {
    show (preferenceInfo) {
      this.preferenceInfo = preferenceInfo
      this.visible = true
    },
    disabledDate (date) {
      return date && date > moment().endOf('day')
    }
  }
}
</script>
<style lang="less">

</style>

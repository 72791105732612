<template>
  <a-spin :spinning="spinning">
    <div :id="chartId" style="height: 320px;"></div>
  </a-spin>
</template>

<script>
import { Chart } from '@antv/g2'
import { humanValue, getSourceMonitorHistoryFunc } from '@/utils'

let chart = null

export default {
  name: 'PerformanceChart',
  props: {
    datetimeRange: { type: Array, required: true },
    preferenceInfo: { type: Object, required: true }
  },
  data () {
    return {
      spinning: false
    }
  },
  computed: {
    historyFunc () {
      return getSourceMonitorHistoryFunc(this.preferenceInfo.sourceType)
    },
    chartId () {
      return `${this.preferenceInfo.chartKey.key}.performance`
    }
  },
  mounted () {
    this.render()
  },
  methods: {
    render () {
      if (chart) chart.destroy()
      chart = new Chart({
        container: this.chartId,
        autoFit: true,
        appendPadding: [8, 10, 0, 0]
      })
      chart.scale({
        timestamp: {
          type: 'time',
          mask: 'YYYY-MM-DD HH:mm'
        },
        value: {
          min: 0,
          nice: true,
          alias: '值',
          formatter: value => humanValue(value)
        }
      })
      chart.tooltip({
        showCrosshairs: true
      })
      this.spinning = true

      this.historyFunc(this.preferenceInfo.sourceId, {
        key: this.preferenceInfo.chartKey.key,
        datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm'),
        datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      }).then(res => {
        const data = res.data.data
        chart.data(data)
        chart.line().position('timestamp*value')
        chart.area().position('timestamp*value')
        if (data.length !== 0) {
          let max = data[0]
          let min = data[0]
          for (const datum of data) {
            if (datum.value > max.value) max = datum
            if (datum.value < min.value) min = datum
          }
          chart.annotation().dataMarker({
            direction: 'downward',
            top: true,
            position: [max.timestamp, max.value],
            text: {
              content: `峰值: ${humanValue(max.value)}`
            }
          })
          if (max.timestamp !== min.timestamp) {
            chart.annotation().dataMarker({
              top: true,
              position: [min.timestamp, min.value],
              text: {
                content: `谷值: ${humanValue(min.value)}`
              }
            })
          }
        }
      }).finally(() => {
        chart.render()
        this.spinning = false
      })
    }
  },
  watch: {
    datetimeRange () {
      this.render()
    }
  }
}
</script>

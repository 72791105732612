<template>
  <a-form-model-item prop="key">
    <a-select :options="keyData" :value="defauleOption" :labelInValue="true" placeholder="请选择监控项"
      @change="change"></a-select>
  </a-form-model-item>
</template>

<script>
export default {
  name: 'KeySeclect',
  props: {
    sourceType: { type: String },
    osType: { type: String },
    middlewareType: { type: String },
    databaseType: { type: String }
  },
  data () {
    return {
      defauleOption: { }
    }
  },
  mounted () {
    this.defauleOption = this.keyData[0] || {}
  },
  computed: {
    keyData () {
      let keys = []
      switch (this.sourceType) {
        case 'host':
          keys = [
            { key: 'system.cpu.util', label: 'CPU 使用率 (%)' }
          ]
          if (this.osType === 'windows') {
            keys.push(
              { key: 'vm.memory.util', label: '内存使用率 (%)' },
              { key: 'vfs.fs.size[C:,pused]', label: 'C: 磁盘使用率 (%)' }
            )
          } else if (this.osType === 'linux') {
            keys.push(
              { key: 'vm.memory.utilization', label: '内存使用率 (%)' },
              { key: 'system.cpu.load[all,avg1]', label: 'CPU 负载' },
              { key: 'vfs.fs.size[/,pused]', label: '/: 磁盘使用率 (%)' },
              { key: 'net.if.in["eth0"]', label: '网卡 eth0: 数据接收 (bps)' },
              { key: 'net.if.out["eth0"]', label: '网卡 eth0: 数据发送 (bps)' }
            )
          } else {
            keys = []
          }
          break
        case 'middleware':
          switch (this.middlewareType) {
            case 'nginx':
              keys = [
                { key: 'nginx.requests.total.rate', label: '每秒请求数' },
                { key: 'nginx.connections.active', label: '活跃的连接数' },
                { key: 'nginx.connections.accepted.rate', label: '每秒接受的连接数' },
                { key: 'nginx.connections.dropped.rate', label: '每秒丢弃的连接数' },
                { key: 'nginx.connections.handled.rate', label: '每秒处理的连接数' }
              ]
              break
          }

          break
        case 'database':
          switch (this.databaseType.toLowerCase()) {
            case 'mysql':
              keys = [
                { key: 'mysql.com_insert.rate', label: '增' },
                { key: 'mysql.com_delete.rate', label: '删' },
                { key: 'mysql.com_update.rate', label: '改' },
                { key: 'mysql.com_select.rate', label: '查' },
                { key: 'mysql.queries.rate', label: 'QPS' },
                { key: 'mysql.buffer_pool_utilization', label: '缓存使用率' },
                { key: 'mysql.buffer_pool_efficiency', label: '缓存命中率' },
                { key: 'mysql.slow_queries.rate', label: '慢查询' }
              ]
              break
            case 'postgresql':
              keys = [
                { key: 'pgsql.dbstat.sum.tup_inserted.rate', label: '增' },
                { key: 'pgsql.dbstat.sum.tup_deleted.rate', label: '删' },
                { key: 'pgsql.dbstat.sum.tup_updated.rate', label: '改' },
                { key: 'pgsql.dbstat.sum.tup_fetched.rate', label: '查' },
                { key: 'pgsql.cache.hit["{$PG.URI}","{$PG.USER}","{$PG.PASSWORD}"]', label: '缓存命中率' }
              ]
              break
            case 'oracle':
              keys = [
                { key: 'oracle.session_active_user', label: '活跃用户会话数' },
                { key: 'oracle.processes_count', label: '进程数' },
                { key: 'oracle.buffer_cache_hit_ratio', label: '缓存命中率' },
                { key: 'oracle.session_lock_rate', label: '会话锁' }
              ]
              break
          }
          break
      }
      return keys
    }
  },

  methods: {
    change (v) {
      this.defauleOption = v
      this.$emit('input', v)
    }
  },
  watch: {
    keyData: function (v) {
      this.defauleOption = v[0] || {}
      this.$emit('input', this.defauleOption)
    }
  }
}
</script>
